.schem {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 30px;

        padding: 0 100px;

        &-left {
            display: flex;
            align-items: center;
            gap: 40px;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        cursor: pointer;

        &-icon {
            width: 20px;
            height: 20px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &-text {
            color: #1E1E1D;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    
    &__list {
        display: flex;
        gap: 20px;

        &-positions {
            flex: 1;
            justify-content: flex-end;

            .schem__list-item {
                max-width: 200px;
                flex: auto;
            }
        }

        &-item {
            background: silver;
            flex: 1;
            display: flex;
            align-items: center;
            gap: 20px;

            padding: 5px 10px;

            max-width: 250px;

            &.start {
                background-color: #F5C6A4;
                border: 2px solid #ED9E6F;
            }

            &.worker {
                background-color: #D73B13;
                .schem__item-text {
                    color: #FFF;
                }
            }

            &.supervisor {
                background-color: #E2E2E6;
                border: 2px solid #AAB2BA;
            }

            &.other {
                background-color: #5B646E;
                .schem__item-text {
                    color: #FFF;
                }
            }

            &.heating-network {
                background: #F5C6A4;
                border: 2px solid #ED9E6F;
            }
        
            &.power-station {
                border-color: #D73B13;
                background-color: #D73B13;

                .schem__item-text {
                    color: #FFF;
                }
            }
        
            &.branch {
                background: #E2E2E6;
                border: 2px solid #AAB2BA;
            }
        
            &.sales {
                background-color: #9FA6AF;
                border: 2px solid #737C86;

                .schem__item-text {
                    color: #1E1E1D; 
                }
            }
        
            &.corporate-center {
                border-color: #5B646E;
                background-color: #5B646E;

                .schem__item-text {
                    color: #FFF;
                }
            }
        }

        &-arrow {
            max-width: 190px;

            img {
                width: 100%;
            }
        }
    }

    &__item {
        &-icon {
            min-width: 40px;
            width: 40px;
            height: 40px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &-text {
            display: flex;
            align-items: center;
            justify-content: center;

            text-align: center;

            color: #1E1E1D;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        
    }
}
