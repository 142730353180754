.developing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    margin-bottom: 230px;
    margin-top: 15px;

    &__title {
        margin-top: 10px;

        text-align: center;
        font-family: Tahoma;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        background: linear-gradient(90deg, rgba(38, 38, 38, 0.76) 0%, #262626 51.04%, rgba(38, 38, 38, 0.76) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        max-width: 840px;
    }

    &__btn {
        border: 1px solid #CC4014;
        // box-shadow: 0px 4px 44px 0px rgba(232, 106, 60, 0.40);

        padding: 13px 60px 16px;

        background: transparent;

        cursor: pointer;

        span {
            text-align: center;
            text-shadow: 0px 4px 44px rgba(232, 106, 60, 0.40);
            font-family: Tahoma;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
    
            background: radial-gradient(235.49% 97.43% at 50% 50%, #D93F2A 0%, #F56632 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}