.main {
    position: relative;
    overflow: hidden;

    padding: 15px 0;

    &__bg {
        &-top {
            position: absolute;
            top: 0;
            left: 0;
        }

        &-bottom {
            position: absolute;
            right: 0;
            bottom: -350px;
        }
    }
}

.content {
    position: relative;
    z-index: 1;
}