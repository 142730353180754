.structure {

    &-wrapper {
        height: 800px;
        margin-top: 20px;
    }

    &__title {
        font-family: Tahoma;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 14px;
    }

    &-text {
        &__list {
            &-item {
                color: #000;
                font-family: Tahoma;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                opacity: 0.3;
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        &-item {
            display: flex;
            gap: 22px;
            align-items: center;

            .color {
                width: 36px;
                height: 36px;
                min-width: 36px;

                &.green {
                    border: 2px solid #FFF;
                    background: radial-gradient(103.91% 82.57% at 50% 50%, #668F1F 0%, #8EBE3B 100%);
                    box-shadow: 0px 4px 44px 0px rgba(141, 189, 58, 0.30);
                }

                &.orange {
                    border: 2px solid #FFF;
                    background: radial-gradient(97.82% 48.51% at 50% 50%, #CC4014 0%, #F56632 100%);
                    box-shadow: 0px 4px 44px 0px rgba(245, 102, 50, 0.30);
                }

                &.blue {
                    border: 2px solid #FFF;
                    background: radial-gradient(103.91% 82.57% at 50% 50%, #285DC6 0%, #2E97E2 100%, #2E97E2 100%);
                    box-shadow: 0px 4px 44px 0px rgba(46, 150, 226, 0.30);
                }

                &.light-green {
                    border: 2px solid #FFF;
                    background: radial-gradient(103.91% 82.57% at 50% 50%, #30A37F 0%, #39D2A3 100%);
                    box-shadow: 0px 4px 44px 0px rgba(57, 210, 163, 0.30);
                }

                &.yellow {
                    border: 1px solid #FFF;
                    background: radial-gradient(91.13% 36.8% at 50% 50%, #CB7729 0%, #FFA824 100%);
                    box-shadow: 0px 4px 54px 0px rgba(255, 168, 36, 0.80);
                }
            }

            .text {
                color: #000;
                font-family: Tahoma;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}

.react-flow__panel {
    display: none;
}

$gradient: radial-gradient(201.04% 81.08% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.66) 100%);

.card {
    cursor: pointer;
    padding: 0;
    width: 180px;
    border: none;
    border-radius: 0;
    
    a {
        text-decoration: none;
    }

    span {
        display: block;
        padding: 8px 20px 11px 20px;
        text-decoration: none;
    }

    span {
        text-align: center;
        font-family: Tahoma;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background: $gradient;

        color: #FFF;
    }

    &-start.react-flow__node.react-flow__node-default {
        // border: 2px solid #FFF;
        background: radial-gradient(103.91% 82.57% at 50% 50%, #668F1F 0%, #8EBE3B 100%);
        box-shadow: 0px 4px 44px 0px rgba(141, 189, 58, 0.30);
    }

    &-worker.react-flow__node.react-flow__node-default {
        // border: 2px solid #FFF;
        background: radial-gradient(103.91% 82.57% at 50% 50%, #285DC6 0%, #2E97E2 100%, #2E97E2 100%);
        box-shadow: 0px 4px 44px 0px rgba(46, 150, 226, 0.30);
    }

    &-supervisor.react-flow__node.react-flow__node-default {
        // border: 2px solid #FFF;
        background: var(--Radial-orange-gr, radial-gradient(97.82% 48.51% at 50% 50%, #CC4014 0%, #F56632 100%));
        box-shadow: 0px 4px 44px 0px rgba(245, 102, 50, 0.30);
    }

    &-another.react-flow__node.react-flow__node-default {
        // border: 2px solid #FFF;
        background: radial-gradient(103.91% 82.57% at 50% 50%, #30A37F 0%, #39D2A3 100%);
        box-shadow: 0px 4px 44px 0px rgba(57, 210, 163, 0.30);        
    }

    &-yellow.react-flow__node.react-flow__node-default {
        // border: 1px solid var(--White-stroke-gr, #FFF);
        background: radial-gradient(91.13% 36.8% at 50% 50%, #CB7729 0%, #FFA824 100%);
        box-shadow: 0px 4px 54px 0px rgba(255, 168, 36, 0.80);
    }

    &-test.card {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        padding: 12px;
        // padding: 10px 20px 12px;

        width: 200px;
        // opacity: 0.5;
        // max-width: 400px;
        // min-width: 200px;
        // height: 40px;
        // width: 400px;
        // height: 100px;
        // width: 400px;
        // height: 100px;

        border-radius: 20px;

        span {
            color: inherit;
        }

        background: #6B7881;
        // max-height: 100px;
        // overflow: auto;

        &.start {
            background: #FBE7E0;
            border: 1px solid #EDB7A4;
            color: #E85E2D;
        }

        &.end {
            background: #8F99A2;
        }
    }
}

.react-flow__handle {
    pointer-events: none;
    cursor: default;
    opacity: 0;
    visibility: hidden;
}

.two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: flex-end;
}

.react-flow__edge-text {
    fill: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-family: Tahoma;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.trajectories__list {
    display: flex;
    gap: 50px;
}

.react-flow__panel {
    display: inline-block;
}

.react-flow__attribution {
    display: none;
}

.edge__label {
    // background: red;
    width: 100%;
    height: 100%;

    // display: none;
}

.react-flow__edge {
    // pointer-events: none;
}

.edge-label {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    min-width: 100%;
    min-height: 30px;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #6B7881;
    border-radius: 18px;
    padding: 10px 15px;
    font-size: 12px;
    background: #f1f1f1;
}