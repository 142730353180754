.heating-network {
    &__bg {
        padding: 50px 0;
        width: 600px;
    }

    &__circle {
        position: absolute;
        cursor: pointer;
        z-index: 5;

        transition: all 0.5s;

        width: 118px;
        height: 118px;
        object-fit: contain;

        &:hover {
            transform: scale(1.1);
        }

        &.top {
            top: 0;

            &.right {
                right: 120px;
            }

            &.left {
                left: 120px;
            }
        }

        &.left {
            left: 10px;

            &.half-top, &.half-bottom {
                left: -60px;
            }
        }

        &.bottom {
            bottom: 0;

            &.right {
                right: 120px;
            }

            &.left {
                left: 120px;
            }
        }

        &.right {
            right: 10px;

            &.half-top, &.half-bottom {
                right: -60px;
            }
        }

        &.center {
            top: 50%;
            transform: translateY(-50%);

            &:hover {
            transform: translateY(-50%) scale(1.1);
            }
        }

        &.half-top {
            top: 100px;
        }

        &.half-bottom {
            bottom: 100px;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__block {
        position: absolute;
        filter: drop-shadow(0px 24px 34px rgba(0, 0, 0, 0.07));
        height: 52px;
        background-color: #FFF;

        display: grid;
        grid-template-columns: 52px 1fr;
        gap: 0px;
        

        min-width: 255px;
        z-index: 1;

        &-icon {
            background: radial-gradient(91.13% 36.8% at 50% 50%, #CD2355 0%, #F92E6A 100%);
            box-shadow: 0px 4px 54px 0px rgba(249, 46, 106, 0.80);

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 34px;
                height: 34px;
                object-fit: contain;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            gap: 2px;
            justify-content: center;

            color: #000;
            font-family: Tahoma;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            position: relative;
            z-index: 1;
            padding-right: 16px;
            padding-left: 13px;

            background-color: #FFF;
        }

        &-subtitle {
            font-weight: 700;
        }

        &-arrow {
            position: absolute;
            z-index: -1;
        }

        &.right {
            right: 0;

            .heating-network__block {
                &-arrow {
                    right: calc(100% - 2px);
                    bottom: 50%
                }
            }
        }

        &.top {
            top: 65px;
        }

        &.center {
            top: 50%;
        }

        &.bottom {
            bottom: 15px;

            .heating-network__block {
                &-arrow {
                    bottom: auto;
                    top: 50%;
                }
            }

        }

        &.left {
            .heating-network__block {
                &-arrow {
                    left: calc(100% - 2px);
                }
            }

            &.top {
                .heating-network__block-arrow {
                    bottom: 50%;
                    left: calc(100% - 30px);
                }
            }
        }

        &.orange {
            .heating-network__block-icon {
                background: radial-gradient(91.13% 36.8% at 50% 50%, #CB7729 0%, #FFA824 100%);
                box-shadow: 0px 4px 54px 0px rgba(255, 168, 36, 0.80);
            }
        }

        &.blue {
            .heating-network__block-icon {
                background: radial-gradient(91.13% 36.8% at 50% 50%, #0058A9 0%, #0075E1 100%);
                box-shadow: 0px 4px 54px 0px rgba(0, 117, 225, 0.80);
            }
        }

        &.green {
            .heating-network__block-icon {
                background: radial-gradient(91.13% 36.8% at 50% 50%, #2E9978 0%, #3AD2A3 100%);
                box-shadow: 0px 4px 54px 0px rgba(58, 210, 163, 0.80);            
            }
        }

        &.turquoise {
            .heating-network__block-icon {
                background: radial-gradient(91.13% 36.8% at 50% 50%, #0069B6 0%, #00A2E1 100%);
                box-shadow: 0px 4px 54px 0px rgba(0, 162, 225, 0.80);
            }
        }

        &.purple {
            .heating-network__block-icon {
                background: radial-gradient(91.13% 36.8% at 50% 50%, #8348C5 0%, #A559F9 100%);
                box-shadow: 0px 4px 54px 0px rgba(165, 89, 249, 0.80);
            }
        }
    }
}

.wrapper {
    position: relative;
    width: fit-content;
    margin: 50px auto;
}