* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Tahoma;
}

.btn {
  border: 1px solid #FFF;
  background: radial-gradient(235.49% 97.43% at 50% 50%, #D93F2A 0%, #F56632 100%);
  box-shadow: 0px 4px 44px 0px rgba(232, 106, 60, 0.40);

  padding: 13px 40px 16px;

  color: #FFF;
  font-family: Tahoma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;

  &[disabled] {
    opacity: 0.8;
    background: #b5b5b5;
    box-shadow: none;
    cursor: default;
  }
}

.header {
  background: #F1F2F3;
  height: 60px;
}

.container {
  width: 1200px;
  margin: 0 auto;

  &>a {
    width: fit-content;
    display: block;
  }
}

.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__left {
  display: flex;
  flex-direction: row;
  gap: 45px;
  align-items: center;

  text-align: left;
}

.header__logo {
  width: 73px;
  cursor: pointer;
}

.header__text {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.header__text-title {
  color: #000;
  font-family: Tahoma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header__text-subtitle {
  color: #000;
  font-family: Tahoma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.3;
}

.header__right {
  text-align: right;

  display: flex;
  align-items: center;
  gap: 30px;
}

#root {
  min-height: 100vh;
  display: flex;  
  flex-direction: column;
  background: #FFF;
}

.main {
  flex: 1;
}

.footer {
  background: linear-gradient(180deg, #657480 0%, #515E69 100%);
  padding: 48px 0 55px;
}

.footer__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 84px;

  position: relative;

  &-title {
    position: absolute;
    top: 0;
    left: 321px;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .col-title {
    margin-top: 40px;
  }
}



.footer__logo {
  width: 93px;
  margin-bottom: 32px;
}

.footer__logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__text {
  color: #FFF;
  font-family: Tahoma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;

  margin-bottom: 15px;
  opacity: 0.4;
}

.footer__text-bold {
  color: #FFF;
  font-family: Tahoma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.link {
  cursor: pointer;
  color: #FFF;
  text-decoration-line: underline;
}

.footer__block + .footer__block {
  margin-top: 25px;
}

.footer__block-title {
  color: #FFF;
  font-family: Tahoma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  opacity: 0.4;
}

.footer__block-subtitle {
  color: #FFF;
  font-family: Tahoma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer__block-subtitle + .footer__block-subtitle {
  margin-top: 4px;
}

.footer__link {
  color: #FFF;
  font-family: Tahoma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-decoration-line: underline;
  opacity: 0.5;

  cursor: pointer;
}

.footer__link + .footer__link {
  margin-top: 30px;
}

.title {
  text-align: center;
  font-family: Tahoma;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
  margin-bottom: 30px;
}

.button-back {
  width: 60px;
  height: 60px;

  background: #FFF;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  margin: 0;
  outline: none;
  border: none;

  cursor: pointer;

  overflow: hidden;

  img {
    width: 16px;
    height: 18px;
    transition: all 0.5s;
  }


  &:hover {
    img {
      transform: scale(1.3);
    }
  }
}

.back-wrpper {
  margin-bottom: 20px;
  width: fit-content;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(5px);

  z-index: 10;

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #FFF;
    padding: 50px 40px;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;

    overflow: hidden;
    cursor: pointer;

    display: none;
    
    &::before, &::after {
      content: '';
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__block + &__block {
    margin-top: 20px;
  }

  &__block {
    &-link {
      margin-top: 10px;

      color: #F15922;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      width: fit-content;

      img {
        margin-top: 3px;
        height: 12px;
      }
    }
  }

  &__title {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 40px;
  }

  &__subtitle {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 4px;
  }

  &__btn {
    display: block;

    margin: auto;
    margin-top: 30px;

    text-decoration: none;
  }

  a {
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 40px;
    text-decoration: none;
  }
}

.dropdown {
  position: relative;
  z-index: 1;

  font-family: Tahoma;

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.6;
  }

  &__head {
    border: 1px solid #DCDCDC;
    padding: 10px 14px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-bottom: 2px;
    }

    &-output {
      font-size: 16px;
      font-weight: 700;
    }

    &-text {
      flex: 1
    }

    &-img {
      width: 40px;
      min-width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;

        transform: scale(1.5);
      }
    }
  }

  &__list {
    display: none;
    width: 100%;
    box-shadow: 0px 24px 54px 0px rgba(0, 0, 0, 0.13);

    position: absolute;
    right: 0;
    top: calc(100% + 10px);

    background-color: #FFF;

    max-height: 250px;
    overflow: auto;

    padding: 5px 0;

    &-item {
      cursor: pointer;
      padding: 15px;

      transition: all 0.2s;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        background-color: #F1F2F3;
      }

      &.empty {
        pointer-events: none;
      }
    }
  }

  &.active {
    z-index: 2;

    .dropdown__list {
      display: block;
    }

    .dropdown__head {
      border-color: #CC4014;
    }
  }
}

iframe {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
}

.ggskin.ggskin_image {
  display: none;
}