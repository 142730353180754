.position {
    margin: 90px 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;

    &__img {
        img {
            width: 100%;
            object-fit: contain;
        }
    }

    &__info {
        &-title {
            font-family: Tahoma;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            background: linear-gradient(90deg, rgba(38, 38, 38, 0.76) 0%, #262626 51.04%, rgba(38, 38, 38, 0.76) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            margin-bottom: 32px;

            &.center {
                text-align: center;
                margin-top: 20px;
                font-size: 36px;
            }
        }

        &-block {
            & + & {
                margin-top: 20px;
            }
        }

        &-subtitle {
            font-family: Tahoma;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            opacity: 0.3;
            background: linear-gradient(90deg, rgba(38, 38, 38, 0.76) 0%, #262626 51.04%, rgba(38, 38, 38, 0.76) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            margin-bottom: 4px;
        }

        &-text {
            font-family: Tahoma;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            background: linear-gradient(90deg, rgba(38, 38, 38, 0.76) 0%, #262626 51.04%, rgba(38, 38, 38, 0.76) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.center {
    text-align: center;
    flex: 1;
}