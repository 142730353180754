.node {
    position: absolute;

    display: flex;
    align-items: center;
    gap: 10px;

    width: 450px;
    height: 90px;

    background: #E2E2E6;

    border: 3px solid #AAB2BA;

    padding: 10px;

    color: #1E1E1D;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    font-family: Tahoma;

    &__text {
        flex: 1;
        text-align: center;
        position: relative;
        z-index: 1;
    }

    &__img {
        width: 80px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__arrow {
        position: absolute;
        z-index: -1;

        &-text {
            font-size: 18px;
            line-height: normal;
            color: #1E1E1D;

            div {
                white-space: nowrap;
            }
        }

        &-end {
            position: absolute;
            width: 20px;
            height: 20px;

            top: -2px;
            left: -2px;

            transform: translateX(-50%) rotate(-90deg);

            svg {
                width: 100%;
                height: 100%;

                
            }
        }

        &-brecket {
            position: absolute;
            bottom: calc(100% + 2px);
            left: 50%;
            transform: translateX(-50%);

            border-bottom: 3px solid #AAB2BA;
            border-right: 3px solid #AAB2BA;
            border-left: 3px solid #AAB2BA;

            border-radius: 0 0 50px 50px;

            height: 25px;
            width: 2000px;

            &.down {
                bottom: -25px;

                border-bottom: none;
                border-top: 3px solid #AAB2BA;
                border-radius: 50px 50px 0 0;
            }

            &.left {
                left: -25px;
                top: 0;
                height: 200px;
                width: 25px;

                border-radius: 0 50px 50px 0;
                border-top: 3px solid #AAB2BA;
                border-left: none;

                transform: none;
            }
        }
    }

    &.end {
        background: #5B646E;
        border-color: #5B646E;
        color: #FFFFFF;
    }

    &.start2 {
        background: #F5C6A4;
        border-color: #ED9E6F;
        padding-left: 35px;
    }

    &.start {
        background: #F5C6A4;
        border-color: #ED9E6F;
        padding-left: 35px;

        &::before {
            content: "";

            position: absolute;
            top: 50%;
            left: 0;

            transform: translate(-50%, -50%);

            width: 70px;
            height: 70px;

            background-image: url("../../public/start_icon.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    &.worker {
        color: #FFF;
        border-color: #D73B13;
        background-color: #D73B13;
    }

    &.heating-network {
        background: #F5C6A4;
        border-color: #ED9E6F;

        padding-right: 60px;

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 50px;
            height: 30px;
            background-image: url("../../public/images/positions/heating-network.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.power-station {
        color: #FFF;
        border-color: #D73B13;
        background-color: #D73B13;

        padding-right: 60px;

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 60px;
            height: 60px;
            background-image: url("../../public/images/positions/power-station.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.branch {
        background: #E2E2E6;
        border: 3px solid #AAB2BA;

        padding-right: 60px;

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 40px;
            height: 40px;
            background-image: url("../../public/images/positions/branch.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.sales {
        background-color: #9FA6AF;
        border-color: #737C86;

        padding-right: 60px;

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 60px;
            height: 60px;
            background-image: url("../../public/images/positions/sales.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.corporate-center {
        color: #FFF;
        border-color: #5B646E;
        background-color: #5B646E;

        padding-right: 60px;

        &::after {
            content: "";
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 60px;
            height: 40px;
            background-image: url("../../public/images/positions/corporate-center.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &.big {
        height: 150px;
    }
    

    // &.small {
    //     width: 300px;

    //     .node__img {
    //         min-width: 60px;
    //         width: 60px;
    //         height: 60px;
    //     }
    // }

    &__list {
        position: relative;
        overflow: auto;
        width: 100% !important;
        max-width: 1900px;

        // border: 1px solid #000;

        &-wrapper {
            width: fit-content;
            margin: 0 auto;
        }

        &-pan {
            min-width: 100vw;
        }

        &-title {
            font-family: Tahoma;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 30px;
            margin-top: 20px;
        }
    }

    &.icon {
        &-start,
        &-worker,
        &-supervisor,
        &-other {
            padding-right: 60px;
    
            &::after {
                content: "";

                position: absolute;
                bottom: 5px;
                right: 5px;

                width: 60px;
                height: 60px;

                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.icon-mini {

                &::after {
                    width: 40px;
                    height: 40px;
                }

                padding-right: 40px;
            }
        }

        &-start {
            &::after {
                background-image: url("../../public/images/position_icons/start-position.svg");
            }

            &.icon-mini {
                &::before {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        &-worker {
            &::after {
                background-image: url("../../public/images/position_icons/worker.svg");
            }

            &.icon-mini {
                &::before {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        &-supervisor {
            &::after {
                background-image: url("../../public/images/position_icons/supervisor.svg");
            }
        }

        &-other {
            &::after {
                background-image: url("../../public/images/position_icons/other.svg");
            }
        }
    }

    &__img {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 60px;
        height: 60px;

        background-color: #FFFFFF;
        border-radius: 50%;
        overflow: hidden;
    }

    &.icon-mini {
        .node__img {
            width: 40px;
            height: 40px;
        }
    }

    &.has-preview.node::after {
        content: none;
    }
}